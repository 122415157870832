import React from 'react';

import withBg from '../../helpers/withBg';
import Layout from '../../components/layout';
import SubscribeForm from '../../components/SubscribeForm';
import SEO from '../../components/seo';
import TrustpilotHorizontal from '../../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../../components/Sidebar/SidebarStaticPage';
import './styles.css';

const data = {
  title: 'Bookingrules',
  seoTitle: 'Bookingrules | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class bookingrulesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber, ready } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <h1>Rules &amp; Restrictions</h1>
                <ul>
                  <li>
                    Review your trip details to make sure the dates and times
                    are correct.
                  </li>
                  <li>
                    Check your spelling. Flight passenger names must match
                    government-issued photo ID exactly.
                  </li>
                  <li>
                    Name changes are not permitted once tickets are issued.
                    Tickets are non-transferable and non-refundable.
                  </li>
                  <li>
                    Travelopod does not assume any liability thereafter for any
                    discrepancy in your booking.
                  </li>
                  <li>
                    Total fares include all taxes and fees except additional
                    airline fees such as baggage, seat assignment etc. that may
                    apply.
                  </li>
                  <li>
                    Your credit card may be billed in multiple charges totaling
                    the above amount. The charges may show as ARC, Travelopod or
                    Agent fee.
                  </li>
                  <li>
                    Changes are permitted as per airline's rules, restrictions
                    and penalties are applicable to these fares however change
                    fees and fare differences may apply.
                  </li>
                  <li>
                    Fares are not guaranteed until ticketed. E-ticket will be
                    sent to your registered email address within 3-24 hrs after
                    the payment has been received.
                  </li>
                  <li>
                    All travelers are responsible for obtaining the necessary
                    visas and/or travel documents for all countries they are
                    visiting or transiting through.
                  </li>
                  <li>
                    Reliable information regarding international travel can be
                    found at{' '}
                    <a href="http://www.travel.state.gov/">
                      www.travel.state.gov
                    </a>{' '}
                    and also with the consulate/embassy of the country you are
                    visiting or transiting through. Travelopod will not be
                    responsible if proper travel documents are not available and
                    you are denied entry or transit into a Country.
                  </li>
                  <li>
                    If your itinerary includes two separate tickets operated by
                    more than one airline, please read carefully each such
                    airline's terms and conditions, which can be found on each
                    such airline's website. Each such airline will have its own
                    restrictions, rules, and fees. If one of these flights is
                    affected by an airline change (e.g. cancellation or
                    rescheduling) that causes a Customer to make changes to the
                    other flight, the Customer may be responsible for any fees
                    or ticket cost incurred for making changes to the unaffected
                    flight. Such airlines may charge their own fees for changes,
                    refunds, or if exchanges are requested. You are responsible
                    for complying with each airline's terms and conditions,
                    which may differ (for example, check-in times and limits on
                    baggage size/weight).
                  </li>
                  <li>
                    All flights should be confirmed with the airline directly as
                    they may have last minute schedule changes. You must
                    reconfirm at least 24 hours prior to departure for domestic
                    flights and 72 hours for flights to Hawaii and international
                    destinations.
                  </li>
                  <li>
                    Please note that your seats, meals, frequent flyer and other
                    special requests are "requests only" and cannot be
                    guaranteed by Travelopod. All requests will be forwarded to
                    the airlines. Please contact the airline directly if you
                    want to receive immediate confirmation on your seat
                    assignment or if you have any special requirement such as
                    stretcher or wheelchair assistance.
                  </li>
                  <li>
                    If your credit card declines at the time of the processing
                    your transaction, we will make all efforts to notify you by
                    email within 24 hours. The transaction will not be processed
                    if your credit card has been declined. The fare and any
                    other booking details are not guaranteed in such instance.
                  </li>
                  <li>
                    Travelopod will not be responsible for providing any
                    compensation in case of airline schedule change.We make
                    every attempt to notify the customer of any schedule
                    changes. It is always best to contact the airline to
                    reconfirm your flights within 48 hours of departure.
                  </li>
                  <li>
                    For date change and cancellation, please contact us 72 hours
                    before departure. Any and all changes/cancellations made to
                    the itinerary are restricted and are subject to the airline
                    fare rules, penalties and other applicable charges whichever
                    is more restrictive; most of our tickets do not allow any
                    name changes or rerouting after the booking is completed.
                    Date change is strictly subject to the availability.
                  </li>
                  <li>
                    Check-in Requirements for Domestic Travel - The recommended
                    arrival time at the airport is 2 hours prior to departure
                    when traveling within the United States or India. You must
                    be checked in at least 45 minutes before your scheduled
                    departure time.
                  </li>
                  <li>
                    Check-in Requirements for International Travel - The
                    recommended arrival time at the airport prior to departure
                    of international flights is 3 hours. You must be checked in
                    at least 60 minutes before your scheduled departure time.
                    This minimum requirement applies to all customers
                    checking-in, with or without baggage.
                  </li>
                  <li>Tickets are non-refundable after departure.</li>
                  <li>Ticket has no value if no show before departure.</li>
                </ul>
              </div>
              <SubscribeForm />
            </div>
            <SidebarStaticPage isCanada phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(bookingrulesPage);
